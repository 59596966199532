.login-with-email-content {
  flex: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.password-requirements {
  display: flex;
  flex-direction: column;
  font-size:x-small;
  text-align: left;
  justify-content: left;
  width: 270px;
}

.password-requirements ul {
  padding-left: 0px;
  text-align: left;
  font-size:x-small;
  margin-top: 0px;
}

.create-account-title-container h4 {
  margin: 0px;
}