.performance-update-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;

}

.streaks-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.streaks-row h4 {
  margin: 0px;
  padding: 0px;
}

.achievement-row::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.new-achievement-row {
  display: flex;
  justify-content: center;
}