.feedback-bg {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #0000003b;
  /* Phone Only? */
  max-width: 600px;
  max-height: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.feedback-modal.ephemeral {
    position: fixed;
    width: 80%;
    height: 5%;
    max-width: 600px;
    max-height: 1000px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    top: 5vh;
    right: 0;
    transition: bottom 0.5s ease-in-out;
    z-index: 10;
    border-radius: 2px;
    border: solid;
    border-color: rgba(0, 0, 0, 0.589);
    background-color: rgba(255, 248, 248, 0.507);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeinout 2s linear forwards;
}
.feedback-modal.ephemeral.incorrect-guess {
  background-color: rgba(255, 117, 117, 0.753);
}


.feedback-modal.ephemeral.correct-guess {
  background-color: rgba(101, 172, 124, 0.514);
}

.feedback-modal.persist {
  position: fixed;
  width: 80%;
  height: 5%;
  max-width: 600px;
  max-height: 1000px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 5vh;
  right: 0;
  z-index: 10;
  border-radius: 2px;
  border: solid;
  border-color: rgba(0, 0, 0, 0.589);
  background-color: rgba(255, 248, 248, 0.897);
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeinout {
  0% { opacity: 1; }
  50% { opacity: .75; }
  100% { opacity: 0; display: none; }
}