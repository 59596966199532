.performance-center-container {
  display: flex;
  flex-direction: column;
  flex:1;
  width: 95%;
  border-radius: 2px;
  border-color: darkgray;
  border-width: thin;
  border-style: solid;
  max-height: 300px;
}
.performance-center {
  height: 35%;
  width:100%;
  flex-direction: column;
  display: flex;
}

.achievemenet-row-header {
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
  font-size: small;
  font-weight: bold;
}

.achievement-row {
  height: 100%;
  width:97%;
  gap: 10px;
  margin-left: 3px;
  margin-right: 3px;
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  direction: ltr;
  margin-bottom: 5px;
}

.achievement-row.empty{
 background-color: #F1EFEF;
 border-radius: 10px;
 display: flex;
 align-items: center;
 justify-content: center;
 opacity: 0.8;
 border-color: rgba(46, 42, 31, 0.39);
 border-width: 1px;
 border-style: solid;
}

.achievement-row-empty-cta-text {
  font-size: small;
  padding-left: 5px;
  padding-right: 5px;
}

.logged-out-stats-cta-banner-background {
  position: absolute;
  z-index: 21;
  width: 100%;
  height: 50%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logged-out-stats-cta-banner{
  background-color: #f1efefab;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.achievement-row::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.performace-stats-container {
  padding-top: 5px;
  display: flex;
  flex:1;
  flex-direction: row;
  width: 100%;
  gap:5px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.stats-center {
  display: flex;
  flex-direction: column;
  flex:1;
}

