.guess-button {
  background-color: rgb(184, 184, 202);
  opacity: 0.5;
  padding: 10px;
  border-radius: 10px;
  color:white;
  font-weight: bold;
  border:none;
  min-width: 70px;
}

.guess-button.modal {
  width: 50%;
}


.guess-button.guessable {
  background-color: rgb(184, 184, 202);
  opacity: 1;
  border: 2px;
  border-style: solid;
  border-color:rgb(81, 158, 202);
}

.guess-button.guessable:hover {
  opacity: .8;
}