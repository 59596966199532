.leader-board-container {
  flex: 1;
  width:100%;
  flex-direction: column;
  display: flex;
}

.leader-board-header-container {
  flex: 1;
  width:100%;
  display: flex;
  margin: 0px;
  padding:0px;
  justify-content: center;
  /* margin-bottom: 3px; */
  align-items: center;
}

.leader-board-row-container {
  flex: 1;
  width:100%;
}


.leader-board-header-title-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 10px; */
}

.leader-board-header-title-container h3 {
  padding: .5em;
  margin: 0px; 
}

.leader-board-table-container {
  flex: 5;
  width:100%;
  display: flex;
  flex-direction: column;
  /* background-color: rgb(132, 153, 192); */
  gap:2px
}