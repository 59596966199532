.title-container h1 {
  font-size: 1.7em;
  margin: 0px;
  padding: 0px;
}

.title-container p {
  margin: 0px;
  padding: 0px;
  font-size: large;
}


.title-container h2 {
  margin: 0px;
}

.title-container span {
  cursor: pointer;
  color: rgba(47, 113, 255, 0.685);
}

.email-collection-landing-title h2 {
  font-size: 8%;
}

.email-collection-landing-title h3 {
  font-size: 1em;
}

.email-inputs-container {
  display: flex;
  gap: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
  width: 100%;
}

.email-inputs-container {
  max-width: 400px;
}

.email-input-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0px;
}

.email-input-label h1{
  font-size: x-large;
}

.email-input-label h2 {
  font-size: 1.0em;
  font-weight: 300;
}

.login-button-container {
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
}

.title-descriptions-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 10px;
  flex: 3;
  width: 110%;
}

.title-description-divider {
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: rgba(57, 70, 70, 0.281);
  height: 1.5px;
  min-height: 1.5px;
  width: 95%;
  border-radius: 1px;
}

.title-descriptions-container h3 {
  font-size: 130%;
  margin-bottom: 0px;
}

.title-descriptions-container p {
  margin: 2%;
}

@media(min-width: 450px) {
  .title-container {
    max-width: 80%;
  }
  .title-description-divider{
    max-width: 80%;
  }

}

@media(min-width: 550px) {
  .title-container {
    max-width: 70%;
  }
  .title-description-divider{
    max-width: 70%;
  }
}