.achievement-badge-container {
  max-width: 100px; 
  padding-top: 5px;
  flex:1;
  border-radius: 5%;
  border-color: darkkhaki;
  border-width: thin;
  border-style: solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.achievement-badge-container-image {
  width: 50px;
  height: 50px;
}

.achievement-badge-label-container {
  font-size: xx-small;
}

.achievement-badge-label-container.long {
  font-size: 7px;
}