.winner-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  overflow: scroll;
}

.cash-drop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: #29b990 2px solid;
  background-color: rgba(154, 188, 177, 0.346);
  padding: 10px;
  color:rgb(22, 74, 41);
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.winner-modal a {
  font-size: xx-large;
  color: #367820ae;       /* Link color */
  text-decoration: none; /* Remove underline */
  font-weight: bold;     /* Make text bold */
}

.winner-modal a:hover {
  color: #29b990;        /* Change color on hover */
  text-decoration: underline; /* Underline on hover */
}

.winner-modal-mr-newsie img {
  height: 110px;
  width: 110px;
}

.completed-game-final-details-container { 
  width: 80%;
  justify-content: center;
  gap:15px;
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
}

.set-username-cta {
  background-color: darkorange;
  border-radius: 10px;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.winner-modal-header-text-container h4 {
  margin: 0px;
  padding: 0px;
}

.share-button-wrapper {
  margin: 10px;
}

@keyframes jump {
  0% {
    /* opacity: 0; */
    padding-top: 10px;
    padding-bottom:0px;
  }
  50% {
    /* opacity: 1; */
    padding-top: 0px;
    padding-bottom: 10px;
  }
  80% {
    /* opacity: 1; */
    padding-top: 10px;
    padding-bottom: 0px;
  }
  100% {
    /* opacity: 1; */
    padding-top: 10px;
    padding-bottom: 0px;
  }
}

.winner-modal-mr-newsie {
  animation: jump 1.5s linear infinite, fadeIn 3s;
}

.confetti-container {
  position: absolute;
  top: 0px;
}
