

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Raleway&display=swap");

 .referral-page-container {
   font-family: 'Raleway', sans-serif;
   height: calc(var(--vh, 1vh) * 100);
   display: flex;
   flex-direction: column;
   max-width: 600px;
   max-height: 1000px;
   margin-left: auto;
   margin-right: auto;
 }

 .referral-page-header {
   /* margin: 1px; */
   display: flex;
   flex-direction: row;
   background-color: rgba(152, 174, 214, 0.233);
 }

 .referral-page-header-mr-newsie img {
   height: 30px;
   width: 30px;
   margin-top: 5px;
   margin-bottom: 5px;
   margin-left: 5px;
 }

 .referral-page-header a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

 .referral-page-header-newsie-fyi {
   margin: 10px;
   line-height: 20px;
   font-size: 1.5em;
 }

 .referral-page-email-collection-container {
   padding: 10px;
   display: flex;
   flex:2;
   justify-content: center;
   align-items: flex-start;
   font-size: .7em;
 }

 .referral-page-email-collection-container ul{
   margin-top: 0px;
  font-size: .7em;
  padding-left: 10px;
 }

 /* @media(min-height: 700) {
  .referral-page-header-newsie-fyi {
  }
 } */