.stop-watch {
  font-size: x-large;
  justify-content: center;
  align-items: center;
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.384);
  height: 33px;
  width: 80px;
}

.stop-watch-heading {
  font-size: xx-small;
  font-weight: 900;
}

.stop-watch-container { 
  margin-top: 1px;
  font-family: 'Raleway';
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
}