.profile-screen-layout {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  height:100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.profile-header-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* justify-content: space-evenly; */
  align-items: center;
}