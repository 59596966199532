.landing-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa5e;
  width: 100%;
  background: linear-gradient( #ffffff79, #fff8da);
}

.landing-page-how-to-play-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page-how-to-play-button {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  margin-top: 10px;
  /* background-color: rgb(184, 184, 202); */
  border: solid;
  border-color: rgb(184, 184, 202);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.landing-page-button-container {
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.landing-page-play-button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: left; */
}

.landing-page-play-button {
  background-color: rgb(184, 184, 202);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.landing-page-login-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
}

.landing-page-login-button {
  margin-top: 10px;
  /* background-color: rgb(184, 184, 202); */
  border: solid;
  border-color: rgb(184, 184, 202);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
