

.hamburger-menu-internal-layout {
  width: 100%;
  flex:1;
}


.hamburger-menu-button-container {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;

  /* background-color: ghostwhite; */
}

.logout-button {
  display: flex;
  width: 95%;
  background-color: rgba(39, 39, 38, 0.219);
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 5%;
}

.hamburger-menu-login-button {
  display: flex;
  width: 95%;
  background-color: rgba(42, 189, 42, 0.219);
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 5%;
}

.logout-button:hover{
  opacity: 0.8;
}