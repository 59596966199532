.update-username-modal {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  justify-content: center;
  align-items: center;
}

.update-username-modal-continue-button {
  background-color: rgba(98, 180, 98, 0.719);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  border: 1px;
  border-style: solid;
  border-color: black;
}