.get-newsie-plus-modal-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.get-newsie-plus-modal-header {
  font-size: xx-large;
}

.get-newsie-plus-modal-header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2%;
}

.get-newsie-plus-modal-motivation-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3%;
  margin-left: 4%;
  margin-right: 4%;
  font-weight: bold;
}

.get-newsie-plus-modal-sales-bullets-container {
  margin-top: -4%;
  margin-right: 5%;
  text-align: left;
}

ul {
  margin-top: 20px;
  list-style: none;
  width: 100%;
  padding-left: 10%;
}

li:before {
  content: "🗞️";
  padding-right: 5px;
}

.get-newsie-plus-logo-container {
  margin-top: 10px;
}

.get-newsie-plus-logo-container img {
  width: 100px;
  height: 100px;
}

.get-newsie-plus-modal-plans-title-container {
  margin-top: 3%;
  margin-bottom: 2%;
}

.get-newsie-plus-modal-purcase-plans-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2%;
  margin-bottom: 2%;
}
