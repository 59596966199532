.profile-user-info-container {
  display: flex;
  flex-direction: column;
  /* max-height:200px ; */
  margin: 20px;
}

.profile-user-info-username-container h4 {
  padding: 0px;
  margin: 0px;
  margin-left: 5%;
  text-align: left;
}

.profile-user-info-username-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px;
}

.profile-user-info-edit-username-input-button-container button {
  background-color: rgb(234, 235, 178);
  border: 2px;
  border-color: darkslategrey;
  border-style: solid;
  height: 100%;
  border-radius: 5px;
}

.profile-user-info-edit-username-input-container input {
  /* padding: 10px; */
  padding: 3px;
  width: 50%;
}

.profile-user-info-edit-username-input-button-container h3 {
  padding: 0px;
  margin: 0px;
}

.profile-user-info-username-header-row-container {
  display: flex;
  gap: 10px;
}

.profile-user-info-username-message-container {
  color: crimson;
  font-weight: 1000;
}

.profile-user-info-username-at-container h3 {
  padding: 0px;
  margin: 0px;
}

.profile-user-info-edit-username-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 20px;
}
.profile-user-info-username-at-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  /* border: 1.5px;
  border-radius: 2px;
  border-style: solid;
  border-color: dimgrey; */
  padding-left: 3px;
  padding-right: 3px;
}

.profile-user-info-edit-username-input-button-container {
  margin-left: 5px;
  margin-right: 5px;
}
