/* @import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Quicksand:wght@700&family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Quicksand:wght@700&family=Raleway:wght@400;900&display=swap");
.email-collection-page-container {
  height: 100%;
  /* max-height: 100%; */
  display: flex;
  flex-direction: column;
  background-color: rgb(252, 248, 248);
  width: 100%;
}
.terms-footer {
  display: flex;
  flex:5;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  font-size: 10px;
  margin: 0px;
  padding: 0px;
  align-items: flex-end;
}
.terms-link {
  margin-bottom: 10px;
}


.copyright-text {
  color: black;
  font:bold;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes jump {
  0% {
    /* opacity: 0; */
    padding-top: 10px;
    padding-bottom: 0px;
  }
  50% {
    /* opacity: 1; */
    padding-top: 0px;
    padding-bottom: 10px;
  }
  80% {
    /* opacity: 1; */
    padding-top: 10px;
    padding-bottom: 0px;
  }
  100% {
    /* opacity: 1; */
    padding-top: 10px;
    padding-bottom: 0px;
  }
}

.newsie-logo-container-email-collection {
  animation: jump 1.5s linear infinite, fadeIn 2s;
}

.landing-page-how-to-play-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page-how-to-play-button {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  margin-top: 10px;
  border: solid;
  border-color: rgb(184, 184, 202);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.landing-page-newsie-description {
  margin-top: 0px;
  padding-top: 0px;
}

.landing-page-newsie-icon h1 {
  margin: 0px;
}

.landing-page-newsie-icon {
  max-height: 30%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email-collection-title-container {
  margin: 0px;
  padding: 0px;
}

.email-collection-title-container p {
  margin: 0px;
  /* font-size: .6em; */
}

.landing-page-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.landing-page-play-button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: left; */
}

.landing-page-play-button {
  background-color: rgb(184, 184, 202);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.landing-page-login-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page-login-button {
  margin-top: 10px;
  border: solid;
  border-color: rgb(184, 184, 202);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.email-input-container {
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}

.subscription-confirmation-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.email-input-container input {
  height: 48px;
  padding: 0px;
  border-color: rgba(152, 153, 219, 0.336);
  border-width: 1px;
  caret-color: rgba(101, 102, 161, 0.774);
  border-radius: 3px;
  width: 100%;
  font-size: 100%;
  text-indent: 10px;
}

.email-input-container button {
  background-color: rgba(152, 153, 219, 0.336);
  border: none;
  height: 50px;
  border-radius: 3px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  pointer-events: all;
}

.subscribe-motivator-container {
  border: wheat;
  border-style: solid;
  border-width: 1px;
  background-color: rgba(0, 100, 0, 0.062);
  padding-top: 10px;
  margin: 10px;
  border-radius: 3px;
  max-height: 63%;
  display: flex;
  justify-content: center;
}

.explain-the-concept-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.explain-the-concept-container h1 {
  font-size: 200%;
  margin: 0px;
  /* font-family: 'Quicksand', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Raleway", sans-serif;
}

.explain-the-concept-container h2 {
  font-size: 200%;
  font-weight: 300;
  margin: 0px;
}

.explain-the-concept-container h3 {
  font-size: 100%;
  font-weight: 1000;
  margin: 0px;
}

.confetti-container {
  position: absolute;
  top: 0px;
  width: 200%;
}
