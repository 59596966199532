.local-edit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.story-table {
  display: flex;
  flex-direction: column;

}

.local-edit-container {
  display: flex;
  flex-direction: column;
}

.add-newsie-locally-button {
  background-color: rgba(154, 97, 207, 0.548);
  margin:10px;
  padding: 10px;
}