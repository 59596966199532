.clue-background{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 59, 59, 0.356);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.clue-modal-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 20%;
}
.clue-modal {
  background-color: aliceblue;
  width: 80%;
  height: 100%;
  max-width: 300px ;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid;
  color: rgb(81,158,202);
}