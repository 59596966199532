.Modal {
  position: absolute;
  bottom: -150vh;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transition: bottom 0.5s ease-in-out;
  z-index: 10;
  border-radius: 7px;
  border: solid;
  border-color: rgba(0, 0, 0, 0.589);
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.modal-x-container {
  width: 99%;
  text-align: end;
  font-weight: 600;
}

.bg {
  position: fixed;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.233);
  backdrop-filter: blur(3px);
  /* Phone Only? */
  max-width: 600px;
  max-height: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.Modal.how-to-play {
  height: 95%;
  width: 95%;
  bottom: 2.5%;
  border: none;
}

.Modal.confirmation-modal {
  max-height: 300px;
  height: 50%;
  bottom: 40%;
  width: 60%;
  background-color: rgba(211, 209, 197, 0.938);
}

.Modal.set-password {
  height: 70%;
  bottom: 20%;
  width: 90%;
  background-color: rgba(211, 209, 197, 0.938);
}

.Modal.how-to-play.disappear {
  bottom: -150vh;
}

.Modal.login {
  background-color: rgba(211, 209, 197, 0.938);
  max-height: 400px;
  min-height: 320px;
  /* height: 400px; */
  /* bottom:420%; */
  max-width: 400px;
}
@media (min-height: 400px) {
  .Modal.login {
    bottom: 10%;
  }
}

@media (min-height: 600px) {
  .Modal.login {
    bottom: 20%;
  }
}

@media (min-height: 700px) {
  .Modal.login {
    bottom: 30%;
  }
}

@media (min-height: 800px) {
  .Modal.login {
    bottom: 40%;
  }
}

.Modal.login.disappear {
  bottom: -150vh;
}

.Modal.winner {
  background-color: rgb(230, 225, 225);
  max-height: 98%;
  width: 90%;
}

.Modal.update-username.show {
  background-color: rgb(230, 225, 225);
  /* height: 40%; */
  max-height: 400px;
  width: 90%;
  bottom: 30%;
}

.Modal.winner.show {
  top: 1%;
  bottom: unset;
}

.Modal.hamburger-menu {
  transition: left 0.5s ease-in-out;
  margin-left: 0px;
  max-width: 300px;
  height: 98%;
  bottom: 0.5vh;
  left: -200vw;
  background-color: ghostwhite;
  /* background-color: rgba(211, 209, 197, 0.938); */
  display: flex;
}

.Modal.get-newsie-plus {
  max-height: 90%;
  bottom: 10%;
  width: 90%;
  background-color: rgb(248, 248, 255);
}

.Modal.hamburger-menu.show {
  left: 0vw;
}

.Modal.hamburger-menu.disappear {
  left: -200vw;
}

.Modal.newsie-categories {
  background-color: rgb(252, 248, 248);
  width: 80%;
  height: 70%;
  bottom: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
