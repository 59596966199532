.error-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap:0px;
}
.auth-error-modal-title-container {
  padding:0px;
  margin:0px;
  font-size: small;

}