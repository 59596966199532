.login {
 height: 100%;
 display: flex;
 overflow: hidden;
 justify-content: center;
 align-items: center;
 -webkit-user-select: none; /* Safari */
 -ms-user-select: none; /* IE 10 and IE 11 */
 user-select: none; /* Standard syntax */
}

.login-button {
  width: 300px;
  background-color: azure;
  border: solid;
  border-radius: 10px;
  border-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 1.0em;
}

.login-button:hover {
  opacity: .8;
}

.login-button:disabled {
  opacity: .6;
  border-color: rgba(0, 0, 0, 0.205);
}

.login-button-label {
  margin: 5px;
}

.email-fieldset {
  width: 300px;
  display: flex;
  flex-direction: column;
  border: none;
  margin-bottom: 0;
  justify-content: center;
}

.email-input-form-label {
  font-weight: bold;
  text-align: left;
  font-size: medium;
}

.email-input {
  outline: none;
  font-size: 1.3em;
  width: 97%;
  background-color: #fff8dc59;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 2px;
}
.email-input:focus {
  border-color: rgba(2, 113, 216, 0.452);

}

.editable-disabled-email {
  display: flex;
}

input:focus{
  outline: none;
}

.spinner {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}


