.header-container {
  display: flex;
  background-color: ghostwhite;
  max-height: 50px;
  justify-content: left;
  align-items: center;
  padding-left: 1vw;
  padding-right: 2vw;

}

.hamburger-icon {
  margin-top: 9%;
}

.hamburger-menu-icon {
  margin-top: 10%;
  background-color: rgba(136, 137, 148, 0.055);
  border-radius: 5px;
  border: 1px;
  border-color: rgba(169, 169, 169, 0.466);
  border-style: solid;
  margin-bottom: 4px;
}

.header-newsie-title{
  display: flex;
  flex-direction: row;
  gap: 2px;
  margin: auto;
  font-size: 1.3em;
  margin-left: 10px;
  margin-top: 12%;
}

.newsie-menu-icon-container {
  flex-direction: row;
  display: flex;
  margin-left: 1%;
}

.newsie-header-middle {
  flex: 2.3;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 80%;
  margin-left: 10%;
}

.instructions-question-icon-layout {
  max-height: 90%;
  display: flex;
  flex:1;
  justify-content: center;
  margin-top: 1%;
  padding: 10px;
}


.instructions-question-icon {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.instructions-clue-icon {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.newsie-header-clue-button-container {
  width:50%;
}

.newsie-header-clue-button-container.no-clue {
  display: none;
}

.newsie-header-clue-button-container.strobe {
  background-color: rgba(42, 85, 165, 0.137);
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;
  border-radius: 10%;
}

.newsie-header-clue-button-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsie-header-clue-button {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 5%;
  font-size: .75em;
}

.newsie-header-clue-button-text {
  font-weight: 900;
  font-size: xx-small;
  margin: 3px;
}

.instructions-question-icon-container {
  border-style: solid;
  border-width: .5px;
  border-color: rgba(169, 169, 169, 0.705);
  padding: 1%;
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(255, 0, 0, 0.096);
  min-width: 50px;
  background-color: rgba(136, 137, 148, 0.055);
  margin-bottom: 3px;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
