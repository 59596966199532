.stat-container {
  display: flex;
  flex-direction: column;
  height: 40%;
  width: 28%;
  border-radius: 1%;
  border-radius: 5%;
  border-color: darkgray;
  border-width: thin;
  border-style: solid;
  justify-content: center;
  align-items: center;
  position: relative;
}

.stat-container-blur {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.233);
  backdrop-filter: blur(3px);
}

.stat-badge-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  font-size: x-large;
}

.stat-label {
  font-size: xx-small;
}

.stat-value {
  font-size: xx-small;
  margin-bottom: 5%;
}

.stat-badge-icon-container img {
  width: 15px;
  height: 27px;
}