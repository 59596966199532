.set-password-modal-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.password-requirements-list {
  font-size: small;
}
.set-password-modal-title-container ul {
  list-style-position: outside;
}

.set-password-modal-title-container ul>li {
  padding-left: 15px;
  position: relative;
}

.set-password-modal-title-container ul>li:before {
  content: "-";
  position: absolute;
  left: 5px;
}
