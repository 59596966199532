.newsie-category-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.newsie-category {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex: 1;
  background-color: rgba(152, 255, 255, 0.514);
  padding: 5px;
  min-width: 50%;
  margin: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.get-newsie-plus-button {
  min-width: 50%;
}

.upgrade-to-newsie-plus-cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(189, 183, 107, 0.267);
  border-color: darkkhaki;
  border-width: 3px;
  border-style: solid;
  width: 90%;
  border-radius: 5px;
  font-size: small;
  padding-left: 2px;
  padding-right:2px;
  font-size: large;
}

.newsie-categories-modal-content {
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newsie-categories {
  width:100%
}