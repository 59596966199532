.story-container {
  background-color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  transition: all 1s ease;
  max-height: 100%;
  box-shadow: darkgreen;
  border:solid;
  border-width: 1.5px;
  border-color: rgba(21, 204, 36, 0.664);

}

.story-container.wrong{
  border-color: rgba(204, 21, 21, 0.664);
}


.story-container.open {
  transition: all 1s ease;
}
