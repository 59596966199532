.leader-board-row-container {
  display: flex;
  flex: 1;
  max-height: 20px;
  background-color: rgb(240, 246, 255);
  justify-content: space-between;
  max-width: 85%;
  align-items: center;
}

.leader-board-table-row-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.leader-board-row-username-container {
  margin-left: 5px;
  display: flex;
}

.leader-board-row-score-container {
  margin-right: 5px;
}

.leader-board-row-score-container h4{
  margin: 0px;
}
.leader-board-row-username-container h4 {
  margin: 0px;
}

.leader-board-row-number-container {
  margin-right: 3px;
}