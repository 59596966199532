.get-newsie-plus-modal-purcase-plan {
  width: 45%;
  /* border-radius: 5px; */
  border: solid;
  border-color: rgba(81, 158, 202, 0.555);
  background-color: rgba(255, 255, 255, 0.336);
  box-shadow:10px 5px 5px rgba(81, 158, 202, 0.164);
  display: flex;
  flex-direction: column;
}

.modal-purchase-plan-header {
  font-size: x-large;
  font-weight: 1000;
}

.modal-purchase-plan-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.get-newsie-plus-modal-purchase-plan-price-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.get-newsie-plus-modal-purchase-plan-price {
  font-size: large;
  margin-top: 5px;
}

.get-newsie-plus-modal-purchase-plan-billed-as-price {
  font-size: x-small;
  margin-bottom: 5px;
  margin-top:10px;
}

.get-newsie-plus-modal-purchase-plan-select-button {
  background-color: rgba(0, 128, 0, 0.479);
  padding: 10px;
  color: aliceblue;
  font-weight: bold;
}