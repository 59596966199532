.word-card {
  background-color: white;
  flex: 1;
  margin: 3%;
  border-radius: 5px;
  border: solid;
  border-color: rgb(81, 158, 202);
  border-width: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 100px;
  width: 80%;
  padding: 2px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.word-card.doing-nothing {
  animation: small-tilt-shake 1.0s 1.0s;
  animation-iteration-count: infinite;
}

.word-card.selected {
  background-color: rgba(184, 184, 202, 0.514);
}

.word-card.incorrect {
  background-color: rgba(255, 119, 119, 0.514);
  animation: tilt-shaking 0.3s 0.3s
}

.word-card.correct {
  background-color: rgb(119, 255, 153);
  animation-name: card-fade-out;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

.word-card.longword {
  font-size: 0.8em;
}

.word-card.very-longword {
  font-size: 0.7em;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes small-tilt-shake {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(.75deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-.75deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes card-fade-out {
  0% {
    opacity: 1;
    /* transform: rotateX(90deg); */
  }

  50% {
    opacity: 0.5;
    /* transform: rotateX(0deg); */
  }

  100% {
    display: none;
    opacity: 0;
    /* transform: rotateX(90deg); */
  }
}