.default-hamburger-screen-layout {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  height:100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.default-menu-items-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;

}

.default-menu-header-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
}

.default-view-action-items-container {
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  align-items: center;
  /* background-color: antiquewhite; */
  flex-direction: column;
  gap: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.default-menu-thanks-container {
  margin: 10px;
}
.default-menu-spacer {
  flex:2;
}
.default-view-action-item-container {
  display: flex;
  flex-direction: column;
  flex:1;
  width: 95%;
  border-radius: 2px;
  border-color: darkgray;
  border-width: thin;
  border-style: solid;
}

