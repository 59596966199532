.newsie-category img {
  width: 10px;
  height: 18px;
}

.newsie-category-image-container {
  margin-right: 5%;
}

.newsie-category.disabled{
  opacity: 0.5;
}