.default-login-content {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.login-buttons-container {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-top: 0;
}

.login-button-container {
  width: 300px;
  display: flex;
  height: 20%;
  justify-content: center;
  align-items: center;
}

.default-login-header h3{
  margin-top: 5%;
  font-size: x-large;
  font-weight: 900;
  margin-bottom: 0;
}

.default-login-or h3{
  margin-top: 5%;
  margin-bottom: 0;
  font-size: x-large;
  font-weight: 900;
}

.email-input-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}