.confirmation-modal-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.confirmation-modal-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 5%; */
  flex: 1;
  margin: 10px;
}

.confirmation-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 400px;
  margin-bottom: 10px;
  max-height: 40px;
  flex: 1;
}

.confirmation-modal-button {
  display: flex;
  width: 100px;
  background-color: rgb(241,94,94);
  justify-content: center;
  color: white;
  border-radius: 3px;  
  align-items: center;
}

.confirmation-modal-button.cancel {
  background-color: rgb(77, 77, 77);
}

.confirmation-modal-title {

}

.confirmation-modal-layout img {
 width:192px;
 height: 192px;
}

.confirmation-modal-image-container {
  display: flex;
  justify-content: center;
}