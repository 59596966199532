.incorrect-guesses-square {
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  flex-direction: column;
  width: 33px;
  height: 33px;
}

.incorrect-guesses-heading {
  flex:1;
  margin-bottom: 1px;
  font-weight: 900;
}

.incorrect-guesses-container {
  margin: 1px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  font-size: xx-small;
}