.word-column {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: dotted;
  border-color: rgb(200, 209, 199);
  border-radius: 3px;
  max-width: 30vw;
  background-color: rgba(226, 226, 226, 0.5);
}

.word-column.empty {
  border:none;
}