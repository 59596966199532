.hamburger-menu-item {
  display: flex;
  flex-direction: row;
  width: 95%;
  height: 40px;
  background-color: ghostwhite;
  border-color: rgb(81, 158, 202);
  border-width: 1px;
  border-style: solid;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(167, 167, 167, 0.445);
}
