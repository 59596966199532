.settings-hamburger-menu-layout {
  display: flex;
  flex-direction: column;
  height:100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hamburger-menu-setting-back-header-container {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 5px;
  /* background-color: rgba(0, 0, 0, 0.329); */
}

.hamburger-menu-back-button {
  background-color: rgba(235, 247, 217, 0.205);
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 900;
}

.setting-menu-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 10px;
}