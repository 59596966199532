@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

.unsubscribe-container {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  margin: 0px;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  /* Only phone mode */
  max-width: 600px;
  max-height: 1000px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

html, body {
  margin: 0px;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}


.unsubscribe-confirmation-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.unsubscribe-are-you-sure-container h1 {
 font-size: .9em;
 margin: 10px;
}

html {
  overscroll-behavior: none
}