.game-details {
  display: flex;
  flex-direction: row;
  background-color: ghostwhite;
  justify-content: space-evenly;
  height: 50px;
  align-items: center;
  min-width: 70px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 1vh;
}