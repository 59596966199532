.how-to-play {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  font-size: 1vw;
  align-items: center;
  justify-content: center;
  background-color: black;
  border: solid;
  opacity: .9;
}

.how-to-play-text-container {
  display: flex;
  border: 1px;
  color: wheat;
  font-size: xx-large;
  margin-top: 10%;
  height: 90%;
}

.newsie-screen-3 img {
  height: 100%;
  width: 100%;
  max-width: 200px;
  max-height: 400px;
  border-radius: 5px;
}

.newsie-screen-3 {
  max-width: 100%;
  font-size: x-large;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
}

@media(max-width: 500px) {
  .newsie-screen-3 {
    font-size: .6em;
  }
}

img.how-to-play-newsie-logo {
  max-width: 150px;
  max-height: 150px;
}

@media(max-width: 500px) {
  img.how-to-play-newsie-logo {
    max-width: 130px;
    max-height: 130px;
  }
}


.how-to-play-newsie-logo-container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

ul {
  margin-top: 20px;
  font-size: medium;
  list-style: none;
  width: 100%;
  padding-left: 10%;
  text-align: left;
}

li:before {
  content: "🗞️";
  padding-right: 5px;
}

.tap-screen-container {
  position: absolute;
  z-index: 11;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.how-to-play-taps-cta-text {
  font-size: small;
  margin: 0px;
  padding: 0px;
}
.how-to-play-taps-cta-container {
  /* background-color: violet; */
  flex: 2;
  width: 100%;
  color: #fff;
  justify-content: end;
  display: flex;
  /* align-items: flex-end; */
  flex-direction: column;
  max-height: 100%;
}

.how-to-play-progress-bullets {
  font-size: xx-large;
  margin: 0px;
  padding: 0px;
  line-height: 10px;
  margin-bottom: 10px;
}
.tap-screen-half {
  height: 100%;
  flex: 1;
  color: aliceblue;
  font-weight: 900;
  font-size: large;
  justify-content: right;
  display: flex;
  flex-direction: column;
}

.tap-screen-half-back-chevron {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  flex: 1;
  margin-bottom: 10%;
}

.close-instructions-xbutton {
  text-align: right;
  margin-right: 4%;
}

.how-to-play-three-consice-words {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.video-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.how-to-play {
  height: 100%;
}
@media (min-width: 0px) {
  .how-to-play {
    width: 95%;
    font-size: 2.2vw;
  }
  .how-to-play-headline {
    font-size: 3vw;
  }
}

@media (min-width: 490px) {
  .how-to-play {
    width: 95%;
    font-size: 2vw;
  }
  .how-to-play-headline {
    font-size: 3.3vw;
  }
}

@media (min-width: 540px) {
  .how-to-play {
    width: 95%;
    font-size: 1.8vw;
  }
  .how-to-play-headline {
    font-size: 3.5vw;
  }
}

@media (min-width: 640px) {
  .how-to-play {
    width: 95%;
    font-size: 1.6vw;
  }
  .how-to-play-headline {
    font-size: 3vw;
  }
}
@media (min-width: 700px) {
  .how-to-play {
    width: 95%;
    font-size: 1.4vw;
  }
  .how-to-play-headline {
    font-size: 3vw;
  }
}

@media (min-width: 800px) {
  .how-to-play {
    width: 95%;
    font-size: 1.2vw;
  }
  .how-to-play-headline {
    font-size: 3vw;
  }
}

@media (min-width: 940px) {
  .how-to-play {
    width: 95%;
    font-size: 1vw;
  }
  .how-to-play-headline {
    font-size: 3vw;
  }
}

@media (min-width: 1040px) {
  .how-to-play {
    width: 95%;
    font-size: 0.8vw;
  }
  .how-to-play-headline {
    font-size: 3vw;
  }
}

@media (min-width: 1140px) {
  .how-to-play {
    width: 95%;
    font-size: 0.8vw;
  }
  .how-to-play-headline {
    font-size: 2vw;
  }
}

.how-to-play-instructions {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  justify-content: center;
}

.how-to-play-word-distil-diagram-container {
  display: flex;
  flex-direction: row;
  height: 20%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.how-to-play-full-headline {
  flex: 1;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 2em;
  justify-content: center;
  align-items: center;
  display: flex;
  font-style: italic;
  padding: 10px;
  border-radius: 5px;
}

.how-to-play-right-pointing-arrow {
  flex: 0;
  margin-left: 5%;
  margin-right: 5%;
}

.thinker-emoji-container h1{
  font-size: xx-large;
  font-size: 80px;
}
